<template>
    <div class="privacy-policy">
        <img src="@/assets/images/prosafe-new-logo.svg" class="privacy-policy__logo" alt="">
        <h1>Privacy Policy ProSafe</h1>

        <strong>By installing the application you indicate that you know the following points of the privacy policy.</strong>

        <div class="privacy-policy__rules">
            <span>1. Respect for your privacy is guaranteed</span>
            <span>2. ProSafe is solely responsible for this application.</span>
            <span>3.Through this application some data such as name, email and other necessary only for the operation of the application are collected</span>
            <span>4. Ip addresses are not registered</span>
            <span>5. Users' email accounts are not accessed</span> 
            <span>6. The application has access to the location, camera and microphone but these are used explicitly to provide security and have evidence in case of generating a dangerous situation for those who use it</span>
            <span>7. Changes to our privacy policy: Our privacy policy may change from time to time. We will post any changes to the privacy policy on this page, so please check back periodically.</span>
        </div>

        <p>
            Contact: If you have any questions about this policy or to report any violation of the policy, please send an email to: <br> <strong>yazan.barqawi@prosafehotels.com</strong>
        </p>
    </div>
</template>